.sign-in {
  .remember-me {
    display: flex;
    width: 100%;

    &__label {
      order: 1;
      width: 75%;

      .sub {
        color: var(--color-text-dimmed);
      }
    }

    &__input {
      order: 2;
      width: 25%;

      // align content
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form-buttons {
    padding-top: 0;
  }

  @include breakpoint(sm, max) {
    .remember-me {
      flex-wrap: wrap;
      text-align: center;

      &__label{
        width: 100%;
      }
      &__input {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}
