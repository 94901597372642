html {
  scrollbar-gutter: stable;
}

body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  min-width: 360px;
}

body > header {
  flex: none;
  width: 100%;
  z-index: 9999;
}

body > main {
  flex: 1 0 auto;
  flex-shrink: 0;

  &::after {
    content: "\00a0";
    display: block;
    height: 0px;
    margin-top: 1rem;
    visibility: hidden;
  }
}

body > footer {
  flex: none;
}
