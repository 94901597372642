.brand {
  img {
    height: 4rem;
    max-width: 800px;
  }

  &:hover {
    filter: brightness(0.6);
  }
}

html#light-theme {
  .brand {
    img {
      filter: invert(92%) sepia(41%) saturate(0%) hue-rotate(331deg) brightness(113%) contrast(111%);
    }
    &:hover {
      filter: invert(46%);
    }
  }
}
