@viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}

@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}

a {
  text-decoration-line: underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.2rem;
  transition: all .3s ease;
}

a:active,
a:link {
  color: var(--color-text);
  text-decoration-style: solid;
}

a:hover,
a:visited {
  color: var(--color-text-dimmed);
  text-decoration-style: dashed;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

body, h1, h2, h3, h4, p, blockquote, figure, ol, ul {
  border: 0;
  margin: 0;
  padding: 0;
}

button {
  -webkit-font-smoothing: inherit;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  transition: .3s;
}

form {
  display: block;
  margin-top: 0;
}

h1 {
  text-align: center;
}

html {
  height: 100%;
  scrollbar-gutter: stable;
}

iframe {
  border: 0;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  display: block;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -.5em;
  vertical-align: baseline;
}

strong {
  font-weight: 700;
}

ul, ol {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,h2,h3,h4,h5,h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table,
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
.border-box {
  box-sizing: border-box;
}
