:root {
  --border-radius: 0.25rem;

  // light theme colors
//   --color-autofill: rgb(165, 165, 0);
//   --color-background: rgb(250, 250, 250);
//   --color-background-hover: #{darken(rgb(250, 250, 250), 15%)};
//   --color-background-offset: rgb(245, 245, 245);
//   --color-button-primary: rgb(65, 91, 206);
//   --color-button-primary-border: rgb(54, 75, 167);
//   --color-error-background: transparent;
//   --color-error-border: rgb(218, 18, 58);
//   --color-error-text: rgb(112, 0, 0);
//   --color-form-field-background: #fff;
//   --color-header-background: rgb(245, 245, 245);
//   --color-info-background: #138211;
//   --color-info-text: #fff;
//   --color-link-active: blue;
//   --color-link-hover: #{lighten(blue, 25%)};
//   --color-link-visited: #{darken(blue, 25%)};
//   --color-neutral-background: #393939;
//   --color-neutral-text: #fff;
//   --color-outline: #ddd;
//   --color-panel-background: rgb(204, 204, 204);
//   --color-panel-border: rgb(148, 148, 148);
//   --color-text: rgb(41, 41, 41);
//   --color-warning-background: #8c700b;
//   --color-warning-text: #fff;
//   --filter-brightness: opacity(0.6);
//   --filter-logo: invert(11%) sepia(8%) saturate(16%) hue-rotate(335deg) brightness(90%) contrast(93%);
// }

// @media (prefers-color-scheme: dark) {
//   :root {
    // dark theme colors
    --color-autofill: rgb(106, 106, 1);
    --color-background: rgb(41, 41, 41);
    --color-background-hover: #{lighten(rgb(41, 41, 41), 15%)};
    --color-background-offset: rgb(45, 45, 45);
    --color-button-primary: rgb(65, 91, 206);
    --color-button-primary-border: rgb(54, 75, 167);
    --color-error-background: #8a2d32;
    --color-error-text: #fff;
    --color-form-field-background: rgb(44, 44, 44);
    --color-form-field-border: rgb(116, 116, 116);
    --color-header-background: rgb(35, 35, 35);
    --color-link-active: blue;
    --color-link-hover: lighten(blue, 25%);
    --color-link-visited: darken(blue, 25%);
    --color-neutral-background: #393939;
    --color-neutral-text: #fff;
    --color-outline: #555;
    --color-panel-background: rgb(53, 53, 53);
    --color-panel-border: rgb(87, 87, 87);
    --color-info-background: #138211;
    --color-info-text: #fff;
    --color-text: rgb(236, 236, 236);
    --color-text-dimmed: rgb(173, 173, 173);
    --color-warning-background: #8c700b;
    --color-warning-text: #fff;
  // }
}
