.alphabet-links {
  align-content: stretch;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  a {
    flex-grow: 1;
    padding: 1rem;
    text-align: center;
  }
  a.active {
    background: red;
  }
}
