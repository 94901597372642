body > footer {
  padding: 1rem 1rem 0;

  p {
    font-size: 0.8rem;
    height: 2rem;
    line-height: 1.6rem;
    margin: 0;
  }

  .site-version {
    float: right;
  }
}
