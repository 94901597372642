.button,
.button:visited,
button {
  background-color: var(--color-button-primary);
  border: 1px solid var(--color-button-primary-border);
  border-radius: 0.4rem;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  min-width: 150px;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    filter: var(--filter-brightness);
  }
}
