.badge {
  background-color: #555555;
  border-radius: .25rem;
  color: #fff;
  display: inline-block;
  padding: .4rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;
  white-space: nonwrap;
  vertical-align: baseline;

  &__pending {
    background-color: #6b6b00;
  }

  &__deleted {
    background-color: #6b0000;
  }
}
