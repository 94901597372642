.flash-container {
  margin: 0 auto;
  min-width: 280px;
  position: relative;
  text-align: center;
  width: 100%;

  .close {
    color: rgba(#fff, 0.75);
    font-family: 'Arial Narrow Bold', sans-serif;
    font-size: 1.3rem;

    background: rgba(#000, 0.1);
    border-left: 1px dotted rgba(#000, 0.2);
    height: 100%;
    padding: 0.75rem 1.75rem 0 0.75rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 20px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.flash {
  background-color: var(--color-neutral-background);
  color: var(--color-neutral-text);
  margin: 0;
  padding: 1.5rem 0;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.flash-error {
  background-color: var(--color-error-background);
  color: var(--color-error-text);
}

.flash-info {
  background-color: var(--color-info-background);
  color: var(--color-info-text);
}

.flash-warn {
  background-color: var(--color-warning-background);
  color: var(--color-warning-text);
}
