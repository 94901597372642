.centered {
  margin: 0 auto;
  text-align: center;
}

.hidden {
  display: none;
  opacity: 0;
  transition: opacity 0.75s, visibility 0.75s;
  visibility: hidden;
}

.show {
  display: initial;
  opacity: 1;
  transition: opacity 0.75s, visibility 0.75s;
  visibility: visible;
}
