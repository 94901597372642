body > header {
  align-items: center;
  background-color: var(--color-header-background);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem 2rem;

  @include breakpoint(sm, min) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .brand-container {
    align-items: center;
    display: flex;
    height: 4rem;
    flex-grow: 2;
    margin: 0;
    order: 1;
    text-align: center;

    @include breakpoint(sm, min) {
      text-align: left;
    }
  }

  .user-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 2rem;
    order: 2;

    @include breakpoint(sm, min) {
      p {
        text-align: right;
        width: 100%;
      }
    }

  }

}
