ul.breadcrumbs {
  border-bottom: 1px solid var(--color-outline);
  border-top: 1px solid var(--color-outline);
  background: var(--color-background-offset);
  padding: 1rem 2rem;
  width: 100%;

  li {
    display: inline-block;
  }
}
