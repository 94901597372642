.form-container {
  margin: 0 auto;
  max-width: 600px;
  text-align: left;

  // Add a border when the content is small, we add 3rem to the max width,
  // otherwise at 600px they're be no margin at all.
  @media (max-width: calc(600px + 3rem)) {
    margin: 0 1rem;
  }

  h1 {
    text-align: center;
  }
}

.button:focus,
button:focus,
input:focus {
  box-shadow: 0 0 0 2pt var(--color-form-field-border);
  outline: 0;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"],
select,
textarea {
  background-color: var(--color-form-field-background);
  border: 1px solid var(--color-form-field-border);
  border-radius: var(--border-radius);
  color: var(--color-text);
  display: block;
  padding: 0.25rem 0.33rem;
  width: 100%;

  &:-webkit-autofill {
    border: 2px solid var(--color-autofill);
  }
}

input[type="password"],
input[type="search"],
input[type="text"],
select {
  filter: none; // remove autofill yellow color that firefox applies
  height: 2.5rem;
}

// Attempt to remove the default background color from autofilled elements
:autofill {
  background: none;
}

select {
  background-image: none;
}

select[multiple] {
  height: auto;
}

.checkbox {
  cursor: pointer;

  input[type="checkbox"] {
    vertical-align: middle;
  }
}

input[type="checkbox"] {
  // remove native appearance
  appearance: none;

  border: 1px solid var(--color-text-dimmed);
  border-radius: var(--border-radius);
  cursor: pointer;
  height: 1.75rem;
  position: relative;
  width: 1.75rem;

  &:active {
    border: 2px solid #34495E;
  }

  &:checked {
    background: var(--color-info-background);
    border: 1px solid var(--color-text);
    color: var(--color-text);

    &::after {
      content: '\2713';
      left: 30%;
      position: absolute;
      top: -10%;
    }
  }
}

label {
  display: block;
  line-height: 2rem;
  padding: 0.33rem 0 0.66rem;
  text-align: left;
  width: 100%;
}

textarea {
  height: 5rem;
  line-height: 1.5rem;
}

.form-buttons {
  padding: 2rem 1rem 1rem;
  text-align: center;
  width: 100%;
}

.form-group {
  padding: 1rem 0;
}

.form-group__input {
  &--invalid {
    background: var(--color-error-background);
    border: 1px solid var(--color-error-border);
    border-radius: var(--border-radius);
    color: var(--color-error-text);
    display: block;
    width: 100%;

    input {
      border-width: 0 0 1px;
      border-color: var(--color-error-border);
      border-radius: 0;
    }
  }

  .invalid-feedback {
    list-style: none;
    padding: 0.75rem 1rem;

    li {
      margin: 0;
    }
  }
}

.form-group__instructions {
  margin: 0 0 1rem 0;
  padding: 0 0 0 1rem;
}

.form-group__label {
  &--sub {
    label {
      margin-top: 1rem;
    }
  }
}
