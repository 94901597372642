$base-line-height: 1.5;
$leading: $base-line-height * 1rem;
$header-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sans-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$mono-font: monospace;
$scale: 1.414;

html {
  font-family: $mono-font;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-hightlight-color: transparent;
}

body {
  font-size: 1.2rem;
  line-height: 1;
  -moz-osx-font-smoothing: greyscale;
  -webkit-font-smoothing: antialiased;
}

main {

  h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  h1 {
    font-family: $mono-font;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    margin: 1rem 0;
    text-align: left;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin: 1.8rem 0 1.2rem;
  }

  h3 {
    font-size: 1.2 * $scale * 1rem;
    line-height: 2.4rem;
    margin: 2.4rem 0 1.2rem;
  }

  h4 {
    font-size: 1 * $scale * 1rem;
    line-height: 2rem;
    margin: 2rem 0 1rem;
  }

  h5 {
    font-size: 0.9 * $scale * 1rem;
    line-height: 1.8rem;
    margin: 1.8rem 0 0.9rem;
  }

  h6 {
    font-size: 0.8 * $scale * 1rem;
    line-height: 1.6rem;
    margin: 1.6rem 0 0.8rem;
  }

  p,
  li {
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1.8rem;
    margin: 1.8rem 0;
  }

  blockquote {
    font-size: 1.2 * $scale * 1rem;
    line-height: 2.4rem;
    margin: 2.4rem;
  }

}
