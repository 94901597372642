main > h1 {
  text-align: center;
}

.account-details {

  dt, dd {
    display: inline-block;
    line-height: 2rem;
  }

  dt {
    font-weight: 900;
    margin: 0;
    width: 25%;
  }

  dd {
    margin: 0;
    width: 75%;
  }

}
